<template lang="">
  <div>
    <!-- dataurl filter status old = ?except_status=request' -->
    <b-card v-if="renderComp">
      <basetable
        ref="basetable"
        :dataurl="dataurl" 
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :custom_filter="custom_filter"
        :is-detail="isDetail"
        :tombol_hide="true"
        :is-add="false"
        :is-edit="false"
        :is-delete="false"
        :isPagination="false"
        :page_size_hide="true"
        :search_hide="false"
        :info_entries="false"
      >
      <template #cell(cost)='data'>
        <span>Rp {{data.item.cost|numFormat}}</span>
      </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol } from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormGroup,

    BFormSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      dataurl:"/all_maintenance",
      baseroute:"semua-perawatan",
      title:"Daftar Perawatan",
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'nomor', label: 'Plat Nomor', sortable: true },
        { key: 'variant_name', label: 'Varian', sortable: true },
        { key: 'cost', label: 'Biaya', sortable: true },
        { key: 'date', label: 'Tanggal', sortable: true },
        { key: 'description', label: 'Deskripsi', sortable: true }
      ],
    //   dataStatus:{
    //     request : {caption: 'Permintaan Perawatan' , color: 'bg-warning'},
    //     plan : {caption: 'Permintaan Perawatan Diterima' , color: 'bg-info'},
    //     realisation : {caption: 'Perawatan Sedang Diproses' , color: 'bg-success'},
    //     reject : {caption: 'Permintaan Perawatan Ditolak' , color: 'bg-danger'},
    //     done: {caption: 'Perawatan Selesai' , color: 'bg-success'},
    //     generated: {caption: 'Generate' , color: 'bg-secondary'},
    //   },
      showFilter : false,
      filter :[],
      active:null,
      custom_filter : true,
      isDetail : true,
      renderComp : true,
    }
  },
  methods:{
    show(){
      // this.filter['filter[active]']=this.active
      // this.filter['filter[region_id]']=this.region.id
      this.$refs.basetable.fetchData()
    },
    getFilter(){
        this.renderComp = false
        this.$nextTick().then(() => {
            this.renderComp = true
        })
    },
    changeSelectStatus(data){
        if(data != null){
            this.filter['filter[status]'] = data
        }
    },
    reset(){
      this.active = null
      this.filter=[]
      this.renderComp = false
        this.$nextTick().then(() => {
            this.renderComp = true
        })
    }
  }
}
</script>
<style lang="">

</style>
